<script setup>
</script>

<template>
  <div class="mrcall-sign-content">
    <div class="headinglogobox">
      <div class="mrcall-title-logo">
      </div>
    </div>
    <div class="sign-central-window">
      <div id="title">
        <slot name="title"></slot>
      </div>
      <slot name="content"></slot>
      <slot name="signin-signup-alternative"></slot>
    </div>
  </div>
</template>

<style scoped lang="less">
@import '../../../assets/style/colors';
.mrcall-sign-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;

  @media screen and (max-width: 640px) {
    background: @mrcall_white;
    .headinglogobox {
      /* Auto layout */
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 24px;
      gap: 8px;

      position: relative;
      width: 100%;
      height: 80px;
      left: 0;
      top: 0;

      .mrcall-title-logo {
        width: 112.68px;
        height: 32px;

        margin: auto;

        background: url(../../../assets/images/mrcall/logo_menu_mrcall.svg) no-repeat;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }

    .sign-central-window {
      /* Auto layout */
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 48px 24px;
      margin-bottom: auto;
      gap: 24px;

      position: relative;
      width: 100%;
      //height: 764px;
      left: 0;
 //     top: calc(60% - 764px / 2 + 40px);

      /* Lara/Root/surface-card */
      background: @mrcall_white;
      border-radius: 6px;

      #title {
        font-family: 'Inter',serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24.5px;
        line-height: 30px;
        color: @mrcall_grey_text;
      }

    }
  }


  @media screen and (min-width: 640px) {
    background: @mrcall_background;
    .headinglogobox {
      position: relative;
      width: 225.35px;
      height: 64px;
      left: calc(50% - 225.35px/2 - 0.32px);
      top: 18px;

      .mrcall-title-logo {
        width: 100% ;
        height: 100% ;
        background: url(../../../assets/images/mrcall/logo_menu_mrcall.svg) no-repeat;
      }
    }

    .sign-central-window {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 48px;
      gap: 24px;

      margin-top: auto;
      margin-bottom: auto;

      position: relative;
      width: 556px;
      height: 510px;
      left: calc(50% - 556px / 2 - 0.32px);

     // top: calc(50% - 510px / 2);

      /* Lara/Root/surface-card */
      background: #FFFFFF;
      /* cardShadow */
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.02);
      border-radius: 6px;

      #title {
        font-family: 'Inter', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24.5px;
        line-height: 30px;
        color: @mrcall_grey_text;
      }
    }
  }
}


</style>