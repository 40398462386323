<template>
  <div class="main-page-content-section">
    <div style="display: flex; flex-flow: column ; justify-content: space-around;">
      <a href="#" @click="goBackward()" class="backward" style="padding: 1em;">
        <i class="pi pi-arrow-left"></i>
        <span class="text" style="margin-left: 1em;">{{$t('views.callbooking.backwardLinkText')}}</span>
      </a>
      <!-- Calendly inline widget begin -->
      <!-- Calendly inline widget end -->
      <div style="  display: flex; flex-flow: row wrap; justify-content: space-around;">
        <div class="calendly-inline-widget" data-url="https://calendly.com/mrcall_ai?primary_color=0068ff" style="width: fit-content ; min-width:320px;height:630px;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router"

export default {
  name: "CallBooking",
  setup() {
  },
  data() {
    let nonEmptyHistory = false;

    const pageTexts = {
      it: {
        backwardLinkText: "Indietro"
      }
    }
    return {
      pageTexts,
      router,
      nonEmptyHistory
    }
  },
  created() {
  },
  mounted() {
    this.nonEmptyHistory = window.history?.state.back !== null && window.history?.state.back !== undefined ;
    let externalScript = document.createElement('script')
    externalScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    document.head.appendChild(externalScript)
  },
  methods: {
    goBackward() {
      if(this.nonEmptyHistory) {
        this.router.back()
      } else {
        this.router.push("/businesses")
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/style/colors';
@import '../assets/style/fonts';

.backward {
  color: @mrcall_grey_text;

  font-weight: 600;
  font-size: 1em;

  .text {
    font-family: 'Inter',serif;
    font-style: normal;
  }

  .icon {
  }
}

.backwardtext {
  font-family: 'Inter',serif;
  font-style: normal;
  color: @mrcall_grey_text;
}
</style>