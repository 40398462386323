<template>
  <div class="main-page-content-section content">
    <Subscription></Subscription>
    <br>
    <Products></Products>
  </div>
  <footer id="pre-footer">
    <PreFooterRequireAssistance/>
  </footer>
</template>
  
<script>
import Subscription from "@/components/Subscription";
import Products from "@/components/Products";
import PreFooterRequireAssistance from "@/components/PreFooterRequireAssistance";

export default {
  components: {Subscription, Products, PreFooterRequireAssistance},
  setup() {
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
  }
}
</script>
  
<style lang="less" scoped>
#pre-footer {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}


.content {
  max-width: 800px;
  margin: auto;

  @media screen and (max-width: 640px) {
    margin: 2em auto auto auto;
    padding: 1em 0 1em 0 !important;
  }
}
</style>