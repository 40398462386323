<template>
  <div style="width: 100% ; heigth: 100%;">
    <ProgressBar v-show="showProgressBar" mode="indeterminate" style="height: .3em"/>
    <div v-if="user">
      <div v-if="totalHits > 0 && queryConvResultReady">
        <div id="search-parameters" class="conversation-table-container field col-12 md:col-4">
          <Calendar style="width: 100%"
                    selectionMode="range"
                    v-model="rangeValue"
                    :manualInput="true"
                    :touchUI="true"
                    :showIcon="true"
                    :showButtonBar="true"
                    :showTime="true"
                    :showSeconds="true"/>
          <Button
              class="m-2"
              :label="$t('components.conversations.downloadDataButtonLabel') + ' tsv'" icon="pi pi-cloud-download" iconPos="right"
              @click="downloadItemsTsv()"
          />
          <Button
              class="m-2"
              :label="$t('components.conversations.downloadDataButtonLabel') + ' json'" icon="pi pi-cloud-download" iconPos="right"
              @click="downloadItemsJson()"
          />
        </div>
        <div class="conversation-table-container">
          <Paginator
              :template="{
                '640px': 'PrevPageLink CurrentPageReport NextPageLink',
                '960px': 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink',
                '1300px': 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
                default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown RowsPerPageDropdown'
              }"
              :alwaysShow="false"
              :rows="size"
              v-model:first="first"
              :rowsPerPageOptions="[10,20,30,50,100]"
              :totalRecords="totalHits"
              @page="onPage($event)">
          </Paginator>
        </div>
        <div id="results" class="conversation-table-container">
          <div v-for="([key, conversation], index) in items" :key="key">
            <div class="conversation">
              <p class="title text-center mb-4 md:text-2xl text-1xl">{{ timestampToDate(conversation.startTimestamp) }}</p>

              <div class="overflow-x-auto relative mb-6">
                <div class="md:block hidden">
                  <table class="w-full border-top-1 border-black">
                    <tr v-if="conversation.contactName">
                      <td class="border-bottom-1 border-gray-200 font-bold py-2"><span class="title text-base">{{ $t('components.conversations.table.contactName')}}</span></td>
                      <td class="border-bottom-1 border-gray-200 py-2 align-right-td">{{ conversation.contactName }}</td>
                    </tr>
                    <tr v-if="conversation.contactNumber">
                      <td class="border-bottom-1 border-gray-200 font-bold py-2"><span class="title text-base">{{ $t('components.conversations.table.contactNumber') }}</span></td>
                      <td class="py-2 border-bottom-1 border-gray-200 align-right-td">{{ conversation.contactNumber }}</td>
                    </tr>
                    <tr v-if="conversation.body">
                      <td class="border-bottom-1 border-gray-200 font-bold py-2"><span class="title text-base">{{ $t('components.conversations.table.body') }}</span></td>
                      <td v-if="conversation.body !== 'UNKNOWN'" class="py-2 border-bottom-1 border-gray-200 align-left-td" v-html="conversation.body"></td>
                      <td v-else class="py-2 border-bottom-1 border-gray-200 align-left-td"></td>
                    </tr>
                    <tr v-if="conversation.audio">
                      <td class="border-bottom-1 border-gray-200 font-bold py-2"><span class="title text-base">{{ $t('components.conversations.table.audioFile') }}</span></td>
                      <td class="py-2 border-bottom-1 border-gray-200 align-right-td">
                        <audio controls>
                          <source :src="'data:audio/mpeg;base64,' + conversation.audio">
                          {{ $t('components.conversations.table.audioNotSupported') }}
                        </audio>
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="md:hidden block list-group">
                  <template v-if="conversation.contactName">
                    <div class="list-group-item border-top-1 border-black text-center">
                      <p class="title text-base mb-2">{{ $t('components.conversations.table.contactName') }}</p>
                      <span>{{ conversation.contactName }}</span>
                    </div>
                  </template>
                  <template v-if="conversation.contactNumber">
                    <div class="list-group-item border-top-1 border-black text-center">
                      <p class="title text-base mb-2">{{ $t('components.conversations.table.contactNumber') }}</p>
                      <span>{{ conversation.contactNumber }}</span>
                    </div>
                  </template>
                  <template v-if="conversation.body">
                    <div class="list-group-item border-top-1 border-black text-center">
                      <p class="title text-base mb-2">{{ $t('components.conversations.table.body') }}</p>
                      <span v-html="conversation.body"></span>
                    </div>
                  </template>
                  <template v-if="conversation.audio">
                    <div class="list-group-item border-top-1 border-black text-center">
                      <p class="title text-base mb-2">{{ $t('components.conversations.table.audioFile') }}</p>
                      <span>
                    <audio controls>
                      <source :src="'data:audio/mpeg;base64,' + conversation.audio">
                      {{ $t('components.conversations.table.audioNotSupported') }}
                    </audio>
                  </span>
                    </div>
                  </template>
                </div>
              </div>

              <div v-if="user" class="text-center md:flex md:align-items-center md:justify-content-center">
                <!--
                <Button
                    class="m-2"
                    :label="$t('components.conversations.editAssistant')" icon="pi pi-pencil" iconPos="right"
                    @click="selectBusiness(key)"
                />
                -->
              </div>
            </div>
            <div v-if="index < items.size -1" class="separator"></div>
          </div>
        </div>
        <div>
          <Paginator
              :template="{
               '640px': 'PrevPageLink CurrentPageReport NextPageLink',
               '960px': 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink',
               '1300px': 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
                default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown RowsPerPageDropdown'
            }"
              :alwaysShow="false"
              :rows="size"
              v-model:first="first"
              :rowsPerPageOptions="[10,20,30,50,100]"
              :totalRecords="totalHits"
              @page="onPage($event)">
          </Paginator>
        </div>
      </div>
      <div v-else-if="queryConvResultReady" class="title">{{ $t('components.conversations.noMessages') }}</div>
    </div>
  </div>
</template>

<script>
import {computed} from 'vue'
import {useStore} from 'vuex'
import axios from "axios"
import router from "@/router"
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "@/firebase/config";
import {interval} from "rxjs";
import Tr from "@/i18n/translation"

export default {
  components: {
  },
  updated() {
  },
  setup: () => {
    return {
    }
  },
  data: () => {
    const store = useStore()
    const periodicEvent = null ;
    const isWebView = computed(() => store.state.isWebview)
    let rangeValue = null
    const locale = Tr.getLocale() ;
    return {
      locale,
      periodicEvent,
      rangeValue,
      businessId: null,
      store,
      isWebView,
      timestampGte: null,
      timestampLte: null,
      router,
      langCode: locale,
      items: {},
      totalHits: 0,
      from: 0,
      size: 10,
      first: 1,
      user: computed(() => store.state.user),
      authIsReady: computed(() => store.state.authIsReady),
      showProgressBar: false,
      isAdmin: router.currentRoute.value.query.admin,
      queryConvResultReady: false
    }
  },
  methods: {
    dateToTimestamp(d) {
      return Date.parse(d)
    },
    timestampToDate(timestamp) {
      var newDate = new Date();
      newDate.setTime(timestamp);
      //console.debug(timestamp, this.langCode)
      return newDate.toLocaleString(this.langCode)
    },
    downloadItemsTsv() {
      const self = this ;
      const headers = {
        "Content-type": "application/json; charset=UTF-8",
        "auth": this.user.accessToken
      };
      const request = {
        businessId: this.businessId,
        lightweight: true,
        timestampLte: this.timestampLte,
        timestampGte: this.timestampGte
      }
      console.debug(request)
      self.showProgressBar = true
      axios.post(process.env.VUE_APP_STARCHAT_URL + "/mrcall/v1/talkmeapp0/customer/conversation/stream/tsv/formatTsvV1",
          request,
          {
            headers: headers,
            responseType: 'blob'
          }
      ).then((response) => {
        const blob = new Blob([response.data], { type: response.data.type });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `conversations.${Date.now()}.tsv`;
        link.click();
        URL.revokeObjectURL(link.href);
        self.showProgressBar = false
      }).catch((error) => {
        console.error(error)
        self.showProgressBar = false
      })
    },
    downloadItemsJson() {
      const self = this ;
      const headers = {
        "Content-type": "application/json; charset=UTF-8",
        "auth": this.user.accessToken
      };
      const request = {
        businessId: this.businessId,
        lightweight: true,
        timestampLte: this.timestampLte,
        timestampGte: this.timestampGte
      }
      console.debug(request)
      self.showProgressBar = true
      axios.post(process.env.VUE_APP_STARCHAT_URL + "/mrcall/v1/talkmeapp0/customer/conversation/stream/json/jsonV1",
          request,
          {
            headers: headers,
            responseType: 'blob'
          }
      ).then((response) => {
        const blob = new Blob([response.data], { type: response.data.type });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `conversations.${Date.now()}.stream.json`;
        link.click();
        URL.revokeObjectURL(link.href);
        self.showProgressBar = false
      }).catch((error) => {
        console.error(error)
        self.showProgressBar = false
      })
    },
    async conversationList() {
      const self = this ;
      const headers = {
        "Content-type": "application/json; charset=UTF-8",
        "auth": this.user.accessToken
      };
      const request = {
        businessId: self.businessId,
        lightweight: false,
        timestampLte: self.timestampLte,
        timestampGte: self.timestampGte,
        from: self.from,
        size: self.size
      }
      console.debug(request)
      self.showProgressBar = true
      axios.post(process.env.VUE_APP_STARCHAT_URL + "/mrcall/v1/talkmeapp0/customer/conversation/search",
          request,
          {
            headers: headers
          }
      ).then((response) => {
        self.queryConvResultReady = true
        const map = new Map()
        if(response.data && response.data.length !== 0) {
          response.data.hits.forEach(function (item) {
            //console.log(item)
            map.set(item.document.id, item.document)
          })
          self.totalHits = response.data.totalHits
        } else {
          self.showProgressBar = false
        }
        self.items = map
        self.showProgressBar = false
      }).catch((error) => {
        console.error(error)
        self.queryConvResultReady = true
        self.showProgressBar = false
        if(error.response.status === 401) {
          self.store.dispatch('logout')
          router.replace('/login')
        }
      })
    },
    onPage(event) {
      //{page: 3, first: 30, rows: 10, pageCount: 6}
      this.from = event.first
      this.size = event.rows
      this.conversationList()
    }
  },
  watch: {
    rangeValue: function(value) {
      const startRange = Date.now() - 31 * 24 * 3600 * 1000
      if(value === null) {
        this.timestampLte = null
        this.timestampGte = startRange
        this.conversationList()
      } else if(value[0] !== null && value[1] !== null) {
        console.debug("value[0] !== null and value[1] !== null", value)
        this.timestampGte = this.dateToTimestamp(value[0])
        this.timestampLte = this.dateToTimestamp(value[1])
        this.conversationList()
      } else if(value[0] !== null) {
        console.debug("value[0] !== null", value)
        this.timestampGte = this.dateToTimestamp(value[0])
        this.conversationList()
      } else {
        console.error("null", value)
      }
    }
  },
  computed: {
  },
  beforeUnmount() {
    console.debug("Before Unmount");
    this.periodicEvent.unsubscribe();
  },
  mounted() {
    this.businessId = this.$route.query.id
    if(this.$route.query.lang) {
      this.langCode = this.$route.query.lang.replace("_", "-")
    }
    if(! this.store.state.user) {
      onAuthStateChanged(auth, (user) => {
        if (user && user.emailVerified && !user.isAnonymous) {
          console.debug("UserStateChanged:", user)
          this.conversationList();
          this.periodicEvent = interval(10000).subscribe(x => {
            if(this.from <= 1) {
              this.conversationList();
            }
          });
        } /* else {
                    router.replace('/login')
                }*/
      })
    } else {
      this.conversationList();
      this.periodicEvent = interval(10000).subscribe(x => {
        if(this.from <= 1) {
          this.conversationList();
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/style/colors';
@import '../assets/style/fonts';
@import '../assets/style/components/conversation';

.special-day {
  text-decoration: line-through;
}

.separator {
  padding-bottom: 2em;
  margin-bottom: 2em;
  border-bottom-color: @mrcall_blue;
  border-bottom-width: medium;
}

.align-right-td {
  text-align: right;
}
.table-odd-tr {
  background-color: @mrcall_light_grey_2;
  overflow-wrap: break-word;
}

.p-button {
  margin-right: .5rem;
}

.p-buttonset {
  .p-button {
    margin-right: 0;
  }
}

.sizes {
  .button {
    margin-bottom: .5rem;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 640px) {
  .p-button {
    margin-bottom: .5rem;

    &:not(.p-button-icon-only) {
      display: flex;
      width: 100%;
    }
  }

  .p-buttonset {
    .p-button {
      margin-bottom: 0;
    }
  }
}
</style>
