import axios from "axios";

export default {
    conversations: function(user, request) {
        const headers = {
            "Content-type": "application/json; charset=UTF-8",
            "auth": user.accessToken
        };
        return axios.post(process.env.VUE_APP_STARCHAT_URL + "/mrcall/v1/talkmeapp0/customer/conversation/search",
            request,
            {
                headers: headers
            }
        ).then((response) => {
            const hits = new Map()
            let totalHits = 0
            let hitsCount = 0
            let maxScore = 0.0
            if(response.data && response.data.length !== 0) {
                response.data.hits.forEach(function (item) {
                    //console.log(item)
                    hits.set(item.document.id, item.document)
                })
                totalHits = response.data.totalHits
                hitsCount = response.data.hitsCount
                maxScore = response.data.maxScore
            }
            return {
                totalHits,
                hits,
                hitsCount,
                maxScore
            }
        })
    }
}