<template>
  <OnboardingBase>
    <template #title>{{ $t('views.onboarding.namephone.titleText') }}</template>
    <template #subtitle>{{ $t('views.onboarding.namephone.subtitleText') }}</template>
    <template #content>
      <div class="item">
        <div class="inputboxtitle">
          {{$t('views.onboarding.namephone.yourNameTitle')}}
        </div>
        <InputText id="userName"
                   class="text-sm"
                   :placeholder="$t('views.onboarding.namephone.yourNamePlaceholder')"
                   v-model="onboardingData.business.name"
                   :class="{'p-invalid':userNameNotValid && submitted}"/>
        <div class="inputboxerror" v-if="userNameNotValid && submitted">
          {{$t('views.onboarding.namephone.nameErrorMessage')}}
        </div>
      </div>
      <div class="item">
        <div class="inputboxtitle">
          {{$t('views.onboarding.namephone.phoneNumberTitle')}}
        </div>
        <MazPhoneNumberInput
            v-model="onboardingData.business.businessPhoneNumber"
            show-code-on-list
            color="info"
            size="sm"
            :fetch-country="false"
            :translations="{
                              countrySelector: {
                                placeholder: $t('views.onboarding.namephone.phoneNumberCountrySelectorPlaceholder'),
                                error: $t('views.onboarding.namephone.phoneNumberCountrySelectorError'),
                              },
                              phoneInput: {
                                placeholder: null, //$t('views.onboarding.namephone.phoneNumberPhoneInputPlaceholder'),
                                example: $t('views.onboarding.namephone.phoneNumberPhoneInputExample'),
                              }
                            }"
            default-phone-number=""
            :default-country-code="onboardingData.business.countryAlpha2"
            :noFlags="false"
            :noExample="false"
            :preferred-countries="countryCodesMap.map((v) => v.country_code)"
            :custom-countries-list="countryCodesMap.map((v) => v.country_code)"
            :ignored-countries="['AC']"
            @update="validatePhone"
        />
        <!--
        <InputText id="phoneNumber"
                   class="text-sm"
                   :placeholder="$t('views.onboarding.namephone.phoneNumberPlaceholder')"
                   v-model="onboardingData.business.businessPhoneNumber"
                   :class="{'p-invalid':phoneNumberNotValid && submitted}"/>
        -->
        <div class="inputboxerror" v-if="phoneNumberNotValid && submitted">
          {{$t('views.onboarding.namephone.phoneNumberErrorMessage')}}
        </div>
        <div class="inputboxsubtitle">
          {{$t('views.onboarding.namephone.phoneNumberSubtitle')}}
        </div>
      </div>
    </template>
    <template #footer>
      <div class="button-left">
        <Button :label="$t('views.onboarding.namephone.moveBackward')" @click="router.push({name: 'OnboardingLanguage'})" class="p-button-text md:w-auto py-3 w-full p-button-left"/>
      </div>
      <div class="button-right">
        <Button :label="$t('views.onboarding.namephone.moveForward')" @click="submit()" class="p-button-text md:w-auto py-3 w-full"/>
      </div>
    </template>
  </OnboardingBase>
</template>

<script>
import OnboardingBase from "@/components/templates/onboarding/Base";
import {computed, ref} from "vue";
import {useStore} from "vuex";
import router from "@/router";
import parsePhoneNumber from "libphonenumber-js";

export default {
  components: {OnboardingBase},
  name: "OnboardingNamePhone",
  setup: function () {
    const store = useStore();

    const countryCodesMap = [
      {name: 'Italy', flag_code: 'it', country_code: 'IT', lang_code: 'it-IT'},
      {name: 'United Kingdom', flag_code: 'gb', country_code: 'UK', lang_code: 'en-GB'},
      {name: 'Spain', flag_code: 'es', country_code: 'ES', lang_code: 'es-ES'},
      {name: 'France', flag_code: 'fr', country_code: 'FR', lang_code: 'fr-FR'},
      {name: 'Germany', flag_code: 'de', country_code: 'DE', lang_code: 'de-DE'}
    ]

    return {
      store,
      user: computed(() => store.state.user),
      router,
      countryCodesMap
    }
  },
  data: function() {
    const onboardingData = this.store.state.onboardingData
    const businessPhoneNumberResults = ref() ;

    function telephoneNumberType(number) {
      const phoneNumber = parsePhoneNumber(number)
      const numberType = phoneNumber.getType()
      if(numberType)
        return numberType.toLowerCase()
      return 'unknown'
    }

    let businessPhoneNumber = computed({
          get: () => onboardingData.business.businessPhoneNumber,
          set: (value) => onboardingData.business.businessPhoneNumber = value
        }
    )

    return {
      userNameNotValid: false,
      phoneNumberNotValid: false,
      submitted: false,
      businessPhoneNumber,
      onboardingData
    }
  },
  methods: {
    telephoneNumberType(number) {
      const phoneNumber = parsePhoneNumber(number)
      const numberType = phoneNumber.getType()
      if(numberType)
        return numberType.toLowerCase()
      return 'unknown'
    },
    validatePhone(value) {
      if(value.isValid) {
        console.log("EVENT1: ", value)
        const phoneNumber = value.e164
        this.onboardingData.business.businessPhoneNumber = phoneNumber
        this.onboardingData.business.numberType = this.telephoneNumberType(phoneNumber)
        this.onboardingData.business.serviceNumber = this.onboardingData.business.serviceNumber?.replace(/#.*$/, '#' + phoneNumber)
        this.onboardingData.business.emailAddress = this.user.email
        this.onboardingData.business.variables.SMS_TO_NUMBER = phoneNumber
        this.onboardingData.business.variables.EMAIL_TO = this.user.email
        this.phoneNumberNotValid = false ;
      } else {
        console.log("EVENT2: ", value)
        this.phoneNumberNotValid = true ;
      }
    },
    submit() {
      console.debug("OnboardingData: ", this.onboardingData);
      this.submitted = true ;
      this.userNameNotValid = ! this.onboardingData.business.name;
      if(this.onboardingData.business.name && this.onboardingData.business.businessPhoneNumber) {
        this.router.push({
          name: "OnboardingSearchBusiness"
        });
      }
    }
  },
  mounted() {
  },
  created() {
    console.log("NamePhone Called with: ", this.onboardingData);
    if(! this.onboardingData.business) {
      this.onboardingData.business = {
        variables: {}
      }
    }
    if(! this.onboardingData.business.languageCountry) {
      this.router.push({
        name: "OnboardingLanguage"
      });
    }
  }
}
</script>

<style scoped lang="less">
@import '../../assets/style/colors';
@import '../../assets/style/components/templates/onboarding';
</style>