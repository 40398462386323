<script setup>
import {useI18n} from "vue-i18n";
import { computed } from 'vue'
const { t } = useI18n()
const data = defineProps(['business', 'variable'])
if(data.business.variables[data.variable.name] === undefined) {
  data.business.variables[data.variable.name] = []
}

function add() {
  const values = data.variable.valuesSelection.map(_ => {return ""})
  data.business.variables[data.variable.name].push(values)
}

function remove(index) {
  data.business.variables[data.variable.name] =
      data.business.variables[data.variable.name].filter(function(_, idx) {
        return idx !== index;
      })
}

function swapElements(index1, index2) {
  var temp = data.business.variables[data.variable.name][index1];
  data.business.variables[data.variable.name][index1] = data.business.variables[data.variable.name][index2];
  data.business.variables[data.variable.name][index2] = temp;
}
</script>

<template>
  <div class="main-items-button-bar">
    <Button icon="pi pi-plus-circle" severity="secondary"
            text rounded
            iconPos="right"
            :label="t('widgets.tuplesvariables.additem')"
            :aria-label="''"
            @click="add()"/>
  </div>
  <div v-for="(item, itemIndex) in business.variables[variable.name]" :key="itemIndex"
       :class="itemIndex % 2 === 0 ? 'even-item' : 'odd-item'">
    <div class="item-button-bar">
      <div>
        <Button icon="pi pi-trash" severity="secondary"
                text rounded
                :label="t('widgets.tuplesvariables.removeitem') + ' ' + itemIndex"
                :aria-label="''"
                @click="remove(itemIndex)"/>
      </div>
      <div class="arrows-block">
        <Button icon="pi pi-arrow-up" severity="secondary"
                :disabled="itemIndex === 0"
                text rounded
                @click="swapElements(itemIndex, itemIndex - 1)"/>
        <Button icon="pi pi-arrow-down" severity="secondary"
                :disabled="itemIndex === business.variables[variable.name].length - 1"
                text rounded
                @click="swapElements(itemIndex, itemIndex + 1)"/>
      </div>
    </div>
    <div v-for="(tupleItem, tupleItemIndex) in variable.valuesSelection" :key="tupleItemIndex">
      <div v-if="tupleItem.title" class="inputboxtitle">{{tupleItem.title}}</div>
      <div>
        <div v-if="tupleItem.type === 'text'">
          <Textarea v-model="business.variables[variable.name][itemIndex][tupleItemIndex]"
                    class="w-full"
          />
        </div>
        <div v-else-if="tupleItem.type === 'boolean'">
          <InputSwitch
              v-model="business.variables[variable.name][itemIndex][tupleItemIndex]"
              trueValue="true"
              falseValue="false"
          />
        </div>
        <div v-else-if="tupleItem.type === 'enum'">
          <Dropdown
              v-model="business.variables[variable.name][itemIndex][tupleItemIndex]"
              :options="tupleItem.enum"
              optionLabel="label"
              optionValue="value"
              :filter="true"
              class="w-full"
              :placeholder="tupleItem.placeholder ? tupleItem.placeholder : variable.description"
              :showClear="true"
          >
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.label}}</span>
              </div>
            </template>
          </Dropdown>
        </div>
        <div v-else>
          <InputText class="w-full"
                     :id="variable.name" type="text"
                     v-model="business.variables[variable.name][itemIndex][tupleItemIndex]"
          />
        </div>
      </div>
      <div v-if="tupleItem.subtitle" class="inputboxsubtitle">{{tupleItem.subtitle}}</div>
    </div>
  </div>
</template>

<style scoped lang="less">
@import '../../assets/style/colors';
@import '../../assets/style/fonts';
@import '../../assets/style/components/templates/configuration_items';

.main-items-button-bar {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.item-button-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.even-item {
  background: @mrcall_white;
  border-radius: 3px;
}

.odd-item {
  background: @mrcall_light_grey_2;
  border-radius: 3px;
}

.arrows-block {
  display: flex;
  flex-direction: row;
}
</style>