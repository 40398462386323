<script setup>
import SupportSection from "../support/SupportSection.vue";
import { useSlots } from 'vue'
const slots = useSlots()
</script>

<template>
  <div id="configuration-page">
    <div id="menu-large">
      <slot name="menu-large"></slot>
    </div>
    <div id="spinner-small">
      <slot name="spinner"></slot>
    </div>
    <div id="support-small">
      <Panel  toggleable collapsed>
        <template #header>
          <div id="header-assistance-icon">
          </div>
          {{$t('components.templates.onboarding.doYouNeedAssistance')}}
        </template>
        <SupportSection></SupportSection>
      </Panel>
    </div>
    <div id="menu-small">
      <slot name="menu-small"></slot>
    </div>
    <div id="divider"></div>
    <div id="mrcall-configuration-base-content">
      <div id="mrcall-configuration-central-section">
        <div id="spinner-large">
          <slot name="spinner"></slot>
        </div>
        <div id="messages">
          <slot name="messages"></slot>
        </div>
        <div id="central-header-section">
          <slot name="beforetitle"></slot>
          <div id="title">
            <slot name="title"></slot>
          </div>
          <div id="subtitle">
            <slot name="subtitle"></slot>
          </div>
        </div>
        <div id="content">
          <slot name="content"></slot>
        </div>
        <div id="footer" v-if="slots.footer">
          <div id="footer-container">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
    <div id="divider"></div>
    <div id="support-large">
      <SupportSection></SupportSection>
    </div>
  </div>
</template>

<style scoped lang="less">
@import '../../../assets/style/colors';

@max-width-screen: 840px;
@small-content-padding: 0.5em;
@large-content-padding: 2em;
@central-header-section-alignment: left;

#configuration-page {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: @mrcall_borders;

  background: @mrcall_white;
  display: flex ;
  flex-direction: row;
  height: 100%;

  #mrcall-configuration-base-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: initial;
    background: @mrcall_white;
    flex-grow: 1;
    overflow: hidden;

    #messages {
      border: 0;
      padding: 0;
      margin: 0;
      width: 100% ;
    }

    @media screen and (max-width: @max-width-screen) {
      #mrcall-configuration-central-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: auto;
        margin-bottom: auto;

        position: relative;
        width: 100%;
        min-height: 100%;
        height: auto;

        left: 0;

        /* Lara/Root/surface-card */
        background: @mrcall_white;

        #spinner-large {
          visibility: hidden;
        }
        #spinner-small {
          border: 0;
          padding: 0;
          margin: 0;
          height: fit-content;
          width: 100%;
        }

        #central-header-section {
          width: 100%;
          padding: @small-content-padding;
          text-align: @central-header-section-alignment;
          #title {
            font-family: 'Inter', serif;
            font-style: normal;
            font-weight: 700;
            font-size: 1.5em;
            line-height: 1em;
            padding-bottom: 0.125em;
            color: @mrcall_dark_grey_text;
          }

          #subtitle {
            font-family: 'Inter',serif;
            font-style: normal;
            font-weight: 400;
            font-size: 1.0em;
            line-height: 1.0em;
            color: @mrcall_grey_text;
          }
        }

        #content {
          padding: @small-content-padding;
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          overflow: scroll;
        }
      }
    }

    @media screen and (min-width: @max-width-screen) {
      background: @mrcall_background;

      #mrcall-configuration-central-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: auto;
        margin-bottom: auto;

        position: relative;
        width: 100%;
        min-height: 100%;
        height: auto;

        /* Lara/Root/surface-card */
        background: @mrcall_white;

        #spinner-small {
          visibility: hidden;
        }
        #spinner-large {
          border: 0;
          padding: 0;
          margin: 0;
          height: fit-content;
          width: 100%;
        }

        #central-header-section {
          width: 100%;
          padding: @large-content-padding;
          text-align: @central-header-section-alignment;
          #title {
            font-family: 'Inter', serif;
            font-style: normal;
            font-weight: 700;
            font-size: 1.5em;
            line-height: 1em;
            padding-bottom: 0.125em;
            color: @mrcall_dark_grey_text;
          }

          #subtitle {
            font-family: 'Inter',serif;
            font-style: normal;
            font-weight: 400;
            font-size: 1em;
            line-height: 1.5em;
            color: @mrcall_grey_text;
          }
        }

        #content {
          padding: @large-content-padding;
          width: 100%;
          max-width: 800px;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          overflow: scroll;
        }
      }
    }
  }

  #divider {
    margin-left: 0;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: @mrcall_borders;
  }

  #support-large {
    display: flex ;
    flex-direction: row;
  }

  #support-small {
    #header-assistance-icon {
      background: url(../../../assets/images/mrcall/littleman/assistance_transparent_bg.svg) no-repeat;

      width: 48px;
      height: 48px;
      mix-blend-mode: multiply;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }

    .p-component {
      width: 100%;
    }
  }

  @media screen and (max-width: @max-width-screen) {
    width: 100%;
    #menu-large {
      display: none;
    }
    #support-large {
      display: none;
    }
    #menu-small {
      padding: @small-content-padding;
      display: flex;
    }
    #support-small{
      display: flex;
    }
  }
  @media screen and (min-width: @max-width-screen) {
    border: 0;
    padding: 0;
    margin: 0;

    #menu-large {
      padding: 2em @small-content-padding;
      display: flex;
    }
    #support-large {
      padding: 2em 1em;
      display: flex;
    }
    #menu-small {
      display: none;
    }
    #support-small{
      display: none;
    }
  }

  #footer {
    width: 100%;
    display: flex;

    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: #DFE7EF;

    @media screen and (max-width: @max-width-screen) {
      flex-direction: column;
      margin-left: auto;
      #footer-container {
        padding: @small-content-padding ;
      }
    }

    @media screen and (min-width: @max-width-screen) {
      flex-direction: row;
      margin-left: auto;
      #footer-container {
        width: 100%;
        padding: @large-content-padding;

      }
    }
  }
}

@media screen and (max-width: @max-width-screen) {
  #configuration-page {
    border: 0;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
}
</style>