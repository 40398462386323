<template>
  <div class="min-h-full flex flex-column justify-content-between">
    <div class="payment-content-section main-page-content-section flex-shrink-0 text-center">
      <p class="title md:text-4xl text-3xl mb-3">{{ $t('views.successpayment.successPayment') }}</p>
      <div v-if="templateName === 'businessplus_onboarding' || templateName === 'businessplus'">
        <p class="title text-3xl md:text-4xl">{{ $t('views.successpayment.bookAnAppointment') }}</p>
        <Button @click="goToPageRoute('/callbooking')" :label="$t('views.successpayment.appointmentButtonText')" />
        <Divider />
      </div>
      <div v-if="assignmentOperationCompleted === true && alreadyAssignedNumber === true">
        <p class="title text-3xl md:text-4xl">{{ $t('views.successpayment.alreadyAssignedNumber') }}</p>
        <Button @click="router.push('/businesses')"
                iconPos="right"
                icon="pi pi-fw pi-info-circle" :label="$t('views.successpayment.alreadyAssignedNumberButtonLabel')" />
        <Divider />
        <p class="text-2xl md:text-2xl">{{ $t('views.successpayment.furtherInfoAboutPersonalArea') }}</p>
        <div v-if="! isWebView">
          <br>
          <p class="text-2xl md:text-2xl" v-html="$t('views.successpayment.announcementApp')"></p>
          <Button @click="gotoExternalPage('https://play.google.com/store/apps/details?id=ai.mrcall.app');"
                  class="m-2"
                  iconPos="right"
                  icon="pi pi-fw pi-android" :label="$t('views.successpayment.androidButtonLabel')" />
          <Button @click="gotoExternalPage('https://apps.apple.com/it/app/mrcall/id1638301178');"
                  class="m-2"
                  iconPos="right"
                  icon="pi pi-fw pi-apple" :label="$t('views.successpayment.appleButtonLabel')" />
        </div>
      </div>
      <div v-else-if="assignmentOperationCompleted === true && assignedNumber !== null">
        <p v-if="templateName === 'businessplus_onboarding' || templateName === 'businessplus'"
           class="title text-3xl md:text-4xl">{{ $t('views.successpayment.callForwardingPageBusinessPlus') }}</p>
        <p v-else class="title text-3xl md:text-4xl">{{ $t('views.successpayment.callForwardingPage') }}</p>
        <Button @click="router.push('/onboardingchoosedevice?id=' + businessId)"
                iconPos="right"
                icon="pi pi-fw pi-info-circle setup_forwarding" :label="$t('views.successpayment.gotoCallForwardingInstructionsButtonLabel')" />
        <Divider />
        <p class="text-2xl md:text-2xl">{{ $t('views.successpayment.furtherInfoAboutPersonalArea') }}</p>
        <div v-if="! isWebView">
          <br>
          <p class="text-2xl md:text-2xl" v-html="$t('views.successpayment.announcementApp')"></p>
          <Button @click="gotoExternalPage('https://play.google.com/store/apps/details?id=ai.mrcall.app')"
                  class="m-2"
                  iconPos="right"
                  icon="pi pi-fw pi-android" :label="$t('views.successpayment.androidButtonLabel')" />
          <Button @click="gotoExternalPage('https://apps.apple.com/it/app/mrcall/id1638301178')"
                  class="m-2"
                  iconPos="right"
                  icon="pi pi-fw pi-apple" :label="$t('views.successpayment.appleButtonLabel')" />
        </div>
      </div>
      <div v-else-if="assignmentOperationCompleted === true">
        <p class="title text-3xl md:text-4xl">{{ $t('views.successpayment.contactSupport') }}</p>
      </div>
    </div>
    <footer id="footer" class="mt-auto">
      <PreFooterRequireAssistance/>
    </footer>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";
import {useStore} from "vuex";
import {computed} from 'vue';
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "@/firebase/config";
import PreFooterRequireAssistance from "@/components/PreFooterRequireAssistance";
import businessUtils from "@/utils/Business";

export default {
  components: {PreFooterRequireAssistance},
  setup: function() {
    let businessId = "";
    return {
      businessId
    }
  },
  data: function () {
    const store = useStore()
    let templateNameValue = null
    let assignmentOperationCompletedValue = false
    let assignNumberValue = null
    let alreadyAssignedNumberValue = false
    const isWebView = computed(() => store.state.isWebview)
    return {
      store,
      isWebView,
      user: computed(() => store.state.user),
      assignNumberValue,
      alreadyAssignedNumberValue,
      assignmentOperationCompletedValue,
      templateNameValue,
      router,
      name: "Success payment page"
    }
  },
  computed: {
    assignmentOperationCompleted: {
      get() {
        return this.assignmentOperationCompletedValue
      },
      set(newValue) {
        this.assignmentOperationCompletedValue = newValue
      }
    },
    assignedNumber: {
      get() {
        return this.assignNumberValue
      },
      set(newValue) {
        this.assignNumberValue = newValue
      }
    },
    alreadyAssignedNumber: {
      get() {
        return this.alreadyAssignedNumberValue
      },
      set(newValue) {
        this.alreadyAssignedNumberValue = newValue
      }
    },
    templateName: {
      get() {
        return this.templateNameValue
      },
      set(newValue) {
        this.templateNameValue = newValue
      }
    }
  },
  methods: {
    updateAssignedNumber() {
      const self = this;
      const businessId = this.$route.query.id;
      businessUtils.getBusiness(this.store, this.user, businessId).then((item) => {
        console.debug("Update assigned number: ", businessId, item, item.serviceNumber);
        self.businessId = businessId
        self.assignedNumber = item.serviceNumber;
        self.templateName = item.template;
        self.assignmentOperationCompleted = true;
      })
    },
    goToPageRoute(route) {
      window.location.href = process.env.VUE_APP_WEBSITE_BASE_URL + route
    },
    gotoExternalPage(url) {
      window.open(url)
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    const businessId = this.$route.query.id;
    if(this.$route.query.template) {
      this.templateNameValue = this.$route.query.template;
    } else {
      this.templateNameValue = 'unknown'
    }
    let isWebView = false ;
    if(this.$route.query.webview) {
      isWebView = JSON.parse(this.$route.query.webview) ;
    }
    this.store.commit('setWebviewMode', isWebView);
    if(! this.store.state.user) {
      onAuthStateChanged(auth, (user) => {
        if (user && user.emailVerified && !user.isAnonymous) {
          console.debug("UserStateChanged:", user)
          this.$gtag.event("add_payment_info", {
            'business_id': businessId,
            'user_email': user.email,
            'plan_name': this.templateNameValue
          });
          this.updateAssignedNumber()
        }
      })
    } else {
      this.$gtag.event("add_payment_info", {
        'business_id': businessId,
        'user_email': this.store.state.user.email,
        'plan_name': this.templateNameValue
      });
      this.updateAssignedNumber()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/style/default';

#footer {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
</style>
